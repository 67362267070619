import moment from "moment-timezone";

export function removeUndefinedKeys<T extends {}>(
  obj: T,
  keyList?: (keyof T)[]
): Partial<T> {
  const shallowCopy = { ...obj };
  // for some reason I need to downcast (keyof T) to string or else TS complains
  const keys = (keyList as string[]) ?? Object.keys(obj);
  for (const k of keys) {
    if (obj[k] === undefined) {
      delete shallowCopy[k];
    }
  }
  return shallowCopy;
}

export function getStartEndOfDay(
  dateYMD: string,
  timezoneOffsetHours: number = 0
): [Date, Date] {
  const hoursToMs = 3600000;
  const msInDay = 86399999;
  try {
    const parts = dateYMD.substring(0, 10).split("-");
    const yyyy = +parts[0];
    const mm = +parts[1] - 1; //https://stackoverflow.com/a/41992352 //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
    const dd = +parts[2];
    const startMilis = Date.UTC(yyyy, mm, dd) + timezoneOffsetHours * hoursToMs;
    const endMilis = startMilis + msInDay;
    if (Number.isNaN(startMilis)) {
      // noinspection ExceptionCaughtLocallyJS
      throw 0;
    }
    return [new Date(startMilis), new Date(endMilis)];
  } catch (e) {
    throw new TypeError(
      `getStartEndOfDay: date ${dateYMD} not readable as YYYY-MM-DD`
    );
  }
}

export function isBetweenDates(
  query: string | Date | moment.Moment,
  start: Date,
  end: Date,
  timezoneOffsetHours: number = 0
): boolean {
  const dateVal =
    query instanceof Date
      ? query
      : moment.isMoment(query)
      ? query.toDate()
      : new Date(query);

  return dateVal >= start && dateVal < end;
}

export function utcToLocal(dateUtc: string | Date | moment.Moment, timeZoneIANA: string): moment.Moment {
  const timezoneSupported = moment.tz.names().includes(timeZoneIANA);
  if(!timezoneSupported) return moment(dateUtc);
  return moment(dateUtc).tz(timeZoneIANA);
}

export function formatDateAsFullText(dateUtc: string | Date | moment.Moment, timeZone: string, hideTz:boolean): string {
  const target = utcToLocal(dateUtc, timeZone);
  if(hideTz){
    // if timezone of target matches client time, do not display the offset
    if(target.utcOffset() === moment().utcOffset()){
      return target.format("MMM DD HH:mm:ss");
    }
  }
  // else if timezone is different from client time, display offset
  return target.format("MMM DD HH:mm:ss (Z)");
}
export function formatDateAsYYYYMMDD(dateUtc: string | Date | moment.Moment, timeZone: string): string {
  return utcToLocal(dateUtc, timeZone).format("YYYY-MM-DD");
}
export function formatDateAsHHmmss(dateUtc: string | Date | moment.Moment, timeZone: string): string {
  return utcToLocal(dateUtc, timeZone).format("HH:mm:ss");
}
export function formatDateAsHmmssA(dateUtc: string | Date | moment.Moment, timeZone: string): string {
  return utcToLocal(dateUtc, timeZone).format("h:mm:ss A");
}

const sizeFactors = {
  B: 1,
  KB: 2 ** 10,
  MB: 2 ** 20,
  GB: 2 ** 30,
  TB: 2 ** 40,
} as const;

export function fileSizeToString(size:number, precision: number = 2): string {
  if (size >= 1024) {
    const kbSize = size / 1024;
    if (kbSize >= 1024) {
      const mbSize = kbSize / 1024;
      if (mbSize >= 1024) {
        const gbSize = mbSize / 1024;
        return `${gbSize.toFixed(precision)} GB`;
      }
      return `${Math.round(mbSize)} MB`;
    }
    return `${Math.round(kbSize)} kB`;
  }
  return `${size} B`;
}

export function fileSizeToNumber(sizeText: string): number {
  const match = sizeText.match(/^([\d.]+)\s*(B|kB|KB|MB|GB|TB)$/i);
  if (!match) {
    console.error("unable to parse as file size: ", sizeText);
    return 0;
  }

  const value = parseFloat(match[1]);
  const unit = match[2].toUpperCase();

  return value * sizeFactors[unit];
}

//may migrate rest of utils eventually
