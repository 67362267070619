import { useLocation } from "react-router-dom";

import { chain } from "lodash";
import moment from "moment";

import {
  DEFAULT_LOCALE,
  IS_STAGING,
  robotIdToName,
  robotNameToId,
  SWR_NUMBER_REGEX,
} from "./constants";

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function latestSummary(list) {
  return list
    .filter((item) => item.fileName.includes("Data-"))
    .sort()
    .reverse()[0];
}

export function softwareVersion(list) {
  return list
    .filter((item) => item.fileName.includes("software_version.json"))
    .sort()
    .reverse()[0];
}

export function diskSpace(list) {
  return list
    .filter((item) => item.fileName.includes("disk_space.json"))
    .sort()
    .reverse()[0];
}

export function currentFiles(list) {
  return list
    .filter((item) => item.fileName.includes("current_files.json"))
    .sort()
    .reverse()[0];
}

export function novHealthLog(list) {
  return list
    .filter((item) => item.fileName.includes("nh.log"))
    .sort()
    .reverse()[0];
}

export function novDataLogs(list) {
  if (!list?.length) return [];
  return list
    .filter((item) => item.fileName.includes(".tsv"))
    .sort()
    .reverse();
}

export function novEyeLogs(list) {
  if (!list?.length) return [];
  return list
    .filter((item) => item.fileName.includes(".log"))
    .sort((a, b) => {
      const aString = a.fileName.split(".")[2];
      const bString = b.fileName.split(".")[2];

      if (!aString) return -1;

      if (!bString) return 1;

      return aString.localeCompare(bString);
    })
    .reverse();
}

export function novEyeVideos(list) {
  if (!list?.length) return [];
  return list
    .filter((item) => item.fileName.includes(".mp4"))
    .sort((a, b) => {
      if (a.uploadedAt > b.uploadedAt) return -1;
      if (b.uploadedAt > a.uploadedAt) return 1;
      return 0;
    });
}

export function novDataDailyReport(list) {
  return list
    .filter((item) => item.fileName.includes(".pdf"))
    .sort()
    .reverse();
}

export function getPLCAlarms(alarmEntries, headers, id) {
  const dateIndices = [
    headers.indexOf("received_at"),
    headers.indexOf("cleared_at"),
    headers.indexOf("acknowledged_at"),
  ];
  return alarmEntries.map((entry) => {
    const alarm = entry.reduce((obj, value, i) => {
      // arrows in date may exist:
      // ;Logging Software Not Working.( Restart HMI Software);>>2019-04-18 12:17:46 PM;;;Logging_on;;;
      return {
        ...obj,
        [headers[i]]: dateIndices.includes(i)
          ? moment.utc(value, "YYYY-MM-DD h:mm:ss a").toISOString()
          : value,
      };
    }, {});
    return {
      ...alarm,
      _raw: entry.join(";"),
      unit: normalizeRobotName(id),
      type: alarm.message?.toLowerCase?.()?.includes?.("error")
        ? "error"
        : "warning",
    };
  });
}

// Alarm Status ; Time Received ; Time cleared ; Time Acknowledged ; Variable Name ; Value ; Measuring unit ; text ; User - Full name ; Computer name ; Comment
export const PLC_COLUMN = [
  { name: "unit", label: "Unit" },
  { name: "message", label: "Alarm Status" },
  { name: "received_at", label: "Time Received" },
  { name: "cleared_at", label: "Time Cleared" },
  { name: "acknowledged_at", label: "Time Acknowledged" },
  { name: "variable_name", label: "Variable Name" },
  { name: "value", label: "Value" },
  // { name: 'measuring_unit', label: "Measuring Unit" },
  { name: "operator_name", label: "User - Full name" },
  { name: "computer_name", label: "Computer name" },
  { name: "comment", label: "Comment" },
];

// CE units are using mm in pipe size not cm
export function convertInchesTomm(inches) {
  return Number(inches / 0.04);
}
// eslint-disable-next-line
Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function getDates(startDate, stopDate) {
  // eslint-disable-next-line
  const dateArray = new Array();
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push({
      date: currentDate.toLocaleDateString(DEFAULT_LOCALE),
    });
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
}

export const getDateList = (start, end) => {
  return getDates(new Date(start), new Date(end));
};

export const getWrikeDataList = (array) => {
  return (array ?? [])
    .map((item, index) => {
      const unitNumber = [...item.title.matchAll(SWR_NUMBER_REGEX)][0] || [];

      return {
        ...item,
        key: index,
        unit: unitNumber[1],
        date: new Date(item.createdDate),
      };
    })
    .slice()
    .sort((a, b) => {
      if (new Date(a.createdDate) < new Date(b.createdDate)) {
        return 1;
      }
      if (new Date(a.createdDate) > new Date(b.createdDate)) {
        return -1;
      }
      return 0;
    });
};

// todo - why does this use today instead of 'end'?
export const dataOffsetDate = (lastOnline, today, selected) => {
  if (moment(lastOnline).isBetween(selected, today)) {
    return lastOnline;
  } else {
    return selected;
  }
};

/**
 * @param {string} timeZone - Noda System timezone identifier
 * @returns Timezone string in {country/continent}/{locality}
 */
export function getTimeZone(timeZone) {
  // TODO: There should be better way to map this
  switch (timeZone) {
    /* Canada */
    case "Eastern Standard Time": // EST-5
      return "Canada/Eastern";

    case "Central Standard Time": // CST UTC-6
      return "Canada/Central";

    /* Mexico */
    case "Central Standard Time (Mexico)": // EAST UTC-6
      return "America/Mexico_City";

    case "Yukon Standard Time": // MST UTC-7
      return "Canada/Yukon";

    case "Mountain Standard Time": // MST UTC-7
      return "Canada/Mountain";

    case "Pacific Standard Time": // PST-8
      return "Canada/Pacific";

    /* China */
    case "China Standard Time": // UTC+8
      return "Asia/Shanghai";

    // Europe
    case "W. Europe Standard Time": // WET UTC+0
      return "WET";

    /* GMT */
    case "GMT Standard Time": // GMT UTC+0
      return "GMT";

    /* EAST */
    case "E. Australia Standard Time": // EAST UTC+10
      return "Australia/Melbourne";

    /* EAST */
    case "AUS Eastern Standard Time": // EAST UTC+10
      return "Australia/Melbourne";

    default:
      return Intl.DateTimeFormat().resolvedOptions().timeZone; //locale browser
  }
}

// Decoding Novarc Pipe schedule numeric encoding back to alphabetical
export const getPipeSchedule = (schedule) => {
  switch (schedule) {
    case 1:
      return "STD";
    case 2:
      return "XH";
    case 3:
      return "XXH";
    case 4:
      return "XS";
    case 5:
      return "XXS";

    default:
      return schedule;
  }
};

/**
 * Returns date string in format of ['YYYY-MM-DD', 'YYYY-MM-DD'] from input passed
 * from DatePicker / RangePicker based on the type
 * @param {string} type
 * @param {moment.Moment} value
 * @returns {string[] | null[]} Array of date range as ['YYYY-MM-DD', 'YYYY-MM-DD']
 */
export function GetDateRange(type, value) {
  if (!value) {
    return [null, null];
  }
  if (Array.isArray(value))
    switch (type) {
      case "date":
        return [value[0].format("YYYY-MM-DD"), value[0].format("YYYY-MM-DD")];

      case "week":
      case "month":
      case "quarter":
      case "year":
        return [
          value[0].startOf(type).format("YYYY-MM-DD"),
          value[1].endOf(type).format("YYYY-MM-DD"),
        ];

      default:
        return [value[0].format("YYYY-MM-DD"), value[1].format("YYYY-MM-DD")];
    }
  else {
    switch (type) {
      case "date":
        return [value.format("YYYY-MM-DD"), value.format("YYYY-MM-DD")];

      case "week":
      case "month":
      case "quarter":
      case "year":
        return [
          value.startOf(type).format("YYYY-MM-DD"),
          value.endOf(type).format("YYYY-MM-DD"),
        ];

      default:
        return [value.format("YYYY-MM-DD"), value.format("YYYY-MM-DD")];
    }
  }
}

export function normalizeRobotId(name) {
  const id = `${name}`.replace("SWR-", "");
  return robotNameToId[id] || Number(id);
}
export function normalizeRobotName(id, showPrefix = true) {
  const prefix = showPrefix ? "SWR-" : "";
  const suffix = robotIdToName[id] || String(id);
  return prefix + suffix;
}

/**
 * @param {string | number} id
 * @returns true if Staging robot in Prod environment
 *          false if Staging robot in Staging environment or Prod robot in any environment
 */
export function isStagingRobotInProd(id) {
  const idNum = normalizeRobotId(id);
  if (!IS_STAGING && (idNum === 1 || idNum === 1910)) {
    return true;
  }
  return false;
}
