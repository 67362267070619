import { Progress } from "antd";
import { fileSizeToNumber, fileSizeToString } from "../../libs/utilsTyped";

interface Props {
  data: {
    //"465GB"
    total: string;
    free: string;
    used: string;
    drives:
      | {
          //"C:\\" | "D:\\" | "/"
          label: string;
          total: number;
          free: number;
          used: number;
        }[]
      | null;
  };
}
export default function DiskSpace(props: Props) {
  const { data } = props;
  const total = fileSizeToNumber(data.total);
  const free = fileSizeToNumber(data.free);
  const used = fileSizeToNumber(data.used);
  if(!total) {
      console.error("could not parse disk space object", data);
      return null;
  }
  const ring = (used / total) * 100;
  const hasMultipleDrives = data.drives?.length > 1;

  return (
    <div className="usage">
      <Progress
        className="usage-chart"
        type="circle"
        percent={ring}
        format={() => `${Math.round(ring)} %`}
        strokeColor={ring > 85 ? "#f5222d" : ring > 70 ? "#fa8c16" : "#1890ff"}
      />
      <div className="usage-values">
        <div>{`${fileSizeToString(free, 0)} Left`}</div>
        <div>{`Total ${fileSizeToString(total, 0)}`}</div>
        {hasMultipleDrives &&
          data.drives.map((e, i) => {
            return (
              <div
                key={i}
              >{`${e.label} usage: ${fileSizeToString(e.used,0)}/${fileSizeToString(e.total,0)}`}</div>
            );
          })}
      </div>
    </div>
  );
}
